var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SButtonCreateOrder',{staticClass:"mb-3",attrs:{"to":"purchases/new"}}),_c('TTableAdvance',{attrs:{"items":_vm.purchases,"fields":_vm.fields,"store":"order.order_purchase","reloadable":"","resource":"purchases","enterable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.refresh},scopedSlots:_vm._u([{key:"first_item",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.first_item)?_c('SCardProductItems',{attrs:{"item":item.first_item.product,"itemsCount":item.items_count,"resource":"goods/products"}}):_c('TMessageNotFound')],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageOrderStatus',{attrs:{"store":"order.purchase_statuses","id":item.status_id}})],1)]}},{key:"supplier",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.supplier)?_c('TLink',{staticClass:"text-truncate-0",attrs:{"content":item.supplier.name,"to":_vm.lodash.getReferenceLink('supplier', item.supplier.id)}}):_c('TMessageNotFound',{attrs:{"slug":item.supplier_id}})],1)]}},{key:"trackings",fn:function(ref){
var item = ref.item;
return [_c('td',[(!_vm.lodash.isEmpty(item.trackings))?_c('SMessageTracking',{attrs:{"value":item.trackings[0].code,"checked":item.trackings[0].received}}):_c('TMessageNotFound'),(item.trackings.length > 1)?_c('TMessage',{attrs:{"content":"Tracking","lowercase":"","size":"small","color":"muted","truncate":1},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_vm._v(" +"+_vm._s(item.trackings.length - 1)+" ")]},proxy:true}],null,true)}):_vm._e()],1)]}},{key:"buyer_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.buyer)?_c('TLink',{staticClass:"text-truncate-0",attrs:{"content":item.buyer.name,"to":_vm.lodash.getReferenceLink('user', item.buyer_id)}}):_vm._e()],1)]}},{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":item.currency_id}})],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id,"placeholder":"Order Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"first_item-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter['items.product_id'],"placeholder":"Jancode"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, 'items.product_id', $event)},_vm.filterChange]}})]},proxy:true},{key:"trackings-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter['trackings.code'],"placeholder":"Tracking Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, 'trackings.code', $event)},_vm.filterChange]}})]},proxy:true},{key:"supplier-filter",fn:function(){return [_c('SSelectSupplier',{attrs:{"value":_vm.filter.supplier_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "supplier_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"buyer_id-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter.buyer_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "buyer_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"status-filter",fn:function(){return [_c('SSelectOrderStatus',{attrs:{"store":"order.purchase_statuses","value":_vm.filter.status_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "status_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"created_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setUpdatedAtFilter]}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }